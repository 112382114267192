import React from 'react'
import Layout from '../components-en/layout'
import { Link, useStaticQuery, graphql } from "gatsby"

import Head from '../components-en/head'
import Hero from '../components-en/hero'
import heroImage from "../images/hakkimizda_hero.png"
import altinGunu from "../images/altingunu.svg"
import soru from "../images/soru.svg"

import { RiArrowRightSLine } from 'react-icons/ri';

import '../styles/grid.scss'
import '../styles/index.scss'


const AboutPage = () => {
  const heroContent={
    title:"We want you to save for your dreams!",
    description:"You don’t have to be a cripto money guru or stock broker. It is possible to save money with your friends and family members. The method we all know is digital now. ",
    image:heroImage,
    trackLabel:"about"
  }

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata{
            email
        }
    }
    }
  `)

  return(
    <Layout>
      <Head title="About Us" description="We want you to save for your dreams" />
      <Hero title={heroContent.title}
      description={heroContent.description}
      image={heroContent.image}
      trackLabel={heroContent.trackLabel}/>  
  <div className="hikayemiz">
    <div className="row">  
      <h1>How did we begin?</h1>
          <div className="col-12">
            <p>Hemen şimdi birikim hesaplarınıza baktığında ne kadar birikim yaptığını görüyorsun? Diyelim ki biriktirebildiğiniz biraz para var. Peki birikimini ne sıklıkta artırabiliyorsun?</p>
            <p>Biz biriktiremiyoruz. <span role="img" area-label="Emoji"></span>🤷‍♂️🤷👀</p>
            <img src={altinGunu} alt="Altın Günü" />
            <p>
            Annelerimize nasıl biriktirebildiklerini sorduk. Altın günlerini biz hep kısır börek için yaptıklarını sanıyorduk. Asıl yaptıklarıysa altın günü sayesinde sıra kendilerine geldiğinde elde ettikleri toplu parayla kendilerine istedikleri şeyleri almalarıymış! Hatta halıcıyla anlaşıp her ay bir eve halı alındığını, tencereciyle anlaşıp düdüklü alındığını bile öğrendik. 
            </p>
            <p>
            Biz de bu yılların birikim yöntemini teknolojinin bize sağladığı imkanları da sonuna kadar kullanarak mutlu olarak para biriktirebileceğimiz Çember’i geliştirdik.
            </p>
            <p>
            Hayallerimizi kendi çevremiz veya tanımasak da aynı hayalleri paylaştığımız insanlarla bir araya gelerek çok daha kolay finanse edebileceğimizi biliyoruz. Bunu Çember'le hem güvenilir hem çok kolay hale getirmeye çalıştık.
            </p>
            <Link to="/blog/nasil-yola-ciktik"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Read all of the our story. </span> </Link>
          </div>
      
    </div>
   </div>

  <div className="row">
    <div className="container">
      <div className="col-12">
        <div className="sorukutu">
          <h1>If you have questions, get in touch!</h1>
          <Link to="contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Write us </span> </Link>
          <img src={soru} alt="Soru Sor"/>
        </div>
      </div>
    </div>
  </div>

  <div className="row">
    <div className="container">
      <div className="col-12">
        <div className="isbirligi">
          <h1><span role="img" aria-label="Emoji">🤝</span>Contact us to work together.<span role="img" aria-label="Emoji">🤝</span></h1>
          
          <p>We’re meeting with banks and retailers. Contact us to become our partner. </p>
          <Link to="/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/>  {data.site.siteMetadata.email}  </Link>
        </div>
      </div>
    </div>
  </div>
  
      


    </Layout>
  )
}

export default AboutPage